import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Technology used:`}</h5>
    <p>{`The base is a simple static website created with `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`gatsbyjs`}</a>{`.
On top of it I'm using `}<a parentName="p" {...{
        "href": "https://ar-js-org.github.io/AR.js-Docs/"
      }}>{`AR.js`}</a>{`, which is a library
designed to bring `}<strong parentName="p">{`augmented reality`}</strong>{` to the web. It's features include `}<strong parentName="p">{`image tracking`}</strong>{`,
`}<strong parentName="p">{`location based AR`}</strong>{` and `}<strong parentName="p">{`marker tracking`}</strong>{`.`}</p>
    <p>{`For this use-case I was picking the `}<strong parentName="p">{`marker tracking`}</strong>{` functionality because with that it is possible
to create `}<strong parentName="p">{`markers`}</strong>{` for many different images and then show content when thy are detected by the
library.`}</p>
    <p>{`The idea itself, creating `}<strong parentName="p">{`markers`}</strong>{` for images, load them into the library and show content based on
what was found, sounds pretty easy, but there have been some problems to solve which I will explain
over the next pages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      